import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Text = makeShortcode("Text");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Text sx={{
      fontSize: [4, 5, 6],
      fontWeight: `bold`,
      color: `heading`
    }} mdxType="Text">
  Olá.
    </Text>
    <p>{`Este site destina-se a centralizar todos os meus cadernos e notas que fui tirando ao longo dos anos.
De seguida encontram-se links para todos os recursos públicos:`}</p>
    <h4>{`Ensino Superior (2020-presente)`}</h4>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://diogotc.com/ist"
        }}>{`Licenciatura em Engenharia Informática e de Computadores`}</a>{` no Instituto Superior Técnico(LEIC-A IST)`}</li>
    </ul>
    <h4>{`Ensino Secundário (2017-2020)`}</h4>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://diogotc.com/matematica"
        }}>{`Matemática A`}</a>{` (11º e 12º anos)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://diogotc.com/fq"
        }}>{`Física e Química A`}</a>{` (11º ano)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://diogotc.com/filosofia"
        }}>{`Filosofia`}</a>{` (10º e 11º anos)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://diogotc.com/portugues"
        }}>{`Português`}</a>{` (10º, 11º e 12º anos)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://diogotc.com/fisica"
        }}>{`Física`}</a>{` (12º ano)`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      