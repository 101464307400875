/** @jsx jsx */
import { jsx } from "theme-ui";
// @ts-ignore
import Bottom from "../texts/bottom";
// @ts-ignore
import Hero from "../texts/hero";
import Layout from "@lekoarts/gatsby-theme-minimal-blog/src/components/layout";
import List from "@lekoarts/gatsby-theme-minimal-blog/src/components/list";

const Homepage = () => {
  return (
    <Layout>
      <section sx={{ mb: [5, 6, 7], p: { fontSize: [1, 2, 3], mt: 2 } }}>
        <Hero />
      </section>
      <List>
        <Bottom />
      </List>
    </Layout>
  );
};

export default Homepage;
